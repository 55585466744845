import * as signalR from "@microsoft/signalr";

const orderHubService = {
    connection: null,

    async startConnection() {
        const token = localStorage.getItem('bearer'); // Get the access token from your authentication system
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl((process.env.VUE_APP_API_URL ?? "") + "/hubs/order", { accessTokenFactory: () => token })
            .build();

        await this.connection.start();
    },

    onReceiveMessage(callback) {
        this.connection.on("NewMessage", (message) => {
            callback(message);
        });
    },

    async sendMessage(message) {
        await this.connection.invoke("NewMessage", message);
    },

    async stopConnection() {
        await this.connection.stop();
    },

    async addToGroup(groupId) {
        await this.connection.invoke("AddToGroup", groupId);
    },
};

export default orderHubService;