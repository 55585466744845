<template>
  <NavMenu v-if="isMerchantValidated()" />
  <div class="app-wrapper">
    <router-view />
    <Footer />
  </div>
  <Loader v-show="loading" />
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";
import Loader from "@/components/Loader.vue";
import Footer from "@/components/Footer.vue";
import { emitter } from '@/services/emitter.service';

export default {
  components: { NavMenu, Loader, Footer },
  data() {
    return { loading: false };
  },
  created() {
    emitter.on("showLoader", (e) => {
      this.loading = e;
    });
  },
  methods: {
    isMerchantValidated() {
      return localStorage.getItem("merchant") != undefined;
    },
  },
};
</script>

<style>
:root {
  --primary-color: #209CEE;
  --highlight-color: #E9F5F8;
}

body {
  background-color: #F5F6FE;
}

.app-btn-outline-primary {
    background: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.app-btn-outline-primary:hover {
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
}

.app-btn-outline-danger {
    background: #fff;
    color: crimson;
    border: 1px solid crimson
}

.app-btn-outline-danger:hover {
    background: crimson;
    color: white;
    border: 1px solid crimson
}

.offcanvas {
    z-index: 1051; /* Adjust the value as needed */
}

/* DateRangePicker */
.applyBtn, .applyBtn:hover {
  color: white !important;
  background-color: var(--primary-color) !important;
}
</style>