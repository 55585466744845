import { createWebHistory, createRouter } from "vue-router";
import { canActivate } from '@/services/auth-guard.service';

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Callback",
    name: "Callback",
    component: () => import(/* webpackChunkName: "about" */ '../views/Callback.vue'),
  },
  {
    path: "/Init",
    name: "Init",
    component: () => import(/* webpackChunkName: "about" */ '../views/Init.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/order/:id',
    name: 'Order Detail',
    component: () => import('../views/Order.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;