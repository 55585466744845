<template>
    <header class="app-header fixed-top">
        <div class="app-header-inner">
            <div class="container-fluid py-2">
                <div class="app-header-content">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-auto">
                            <a id="sidepanel-toggler" class="sidepanel-toggler d-inline-block d-xl-none" href="#"
                                @click="onMenuClick()">
                                <i class="fas fa-bars"></i>
                            </a>
                        </div>

                        <div class="app-utilities col-auto">
                            <div class="app-utility-item">
                                <span class="text-muted">{{ merchant }}</span>
                            </div>
                            <div class="app-utility-item">
                                <a href="/"><i class="far fa-bell fs-4"></i><span class="badge bg-danger"
                                        style="margin-left: -7px;" v-if="notificationCount > 0">{{ notificationCount }}</span></a>
                            </div>
                            <div class="app-utility-item app-user-dropdown dropdown">
                                <a class="dropdown-toggle" id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#"
                                    role="button" aria-expanded="false">
                                    <i class="far fa-user-circle fs-4"></i>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="user-dropdown-toggle">
                                    <li>
                                        <button class="dropdown-item" @click="switchMerchant">
                                            Switch Merchant
                                        </button>
                                    </li>
                                    <li>
                                        <button class="dropdown-item" href="#" @click="logOut">
                                            Log Out
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <!--//app-user-dropdown-->
                        </div>
                        <!--//app-utilities-->
                    </div>
                    <!--//row-->
                </div>
                <!--//app-header-content-->
            </div>
            <!--//container-fluid-->
        </div>
        <!--//app-header-inner-->
        <div id="app-sidepanel" class="app-sidepanel">
            <div id="sidepanel-drop" class="sidepanel-drop"></div>
            <div class="sidepanel-inner d-flex flex-column">
                <a href="#" id="sidepanel-close" class="sidepanel-close d-xl-none" @click="onMenuClose()">&times;</a>
                <div class="app-branding">
                    <router-link to="/" class="app-logo">
                        <img class="logo-icon me-2" :src="require('@/assets/logo.png')" alt="logo" /><span
                            class="logo-text">OMS</span>
                    </router-link>
                </div>
                <!--//app-branding-->

                <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
                    <ul class="app-menu list-unstyled accordion" id="menu-accordion">
                        <li class="nav-item">
                            <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->

                            <router-link to="/" class="nav-link">
                                <span class="nav-icon">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="nav-link-text">Orders</span>
                            </router-link>
                            <!--//nav-link-->
                        </li>
                    </ul>
                    <!--//app-menu-->
                </nav>
                <!--//app-nav-->
                <div class="app-sidepanel-footer">
                    <nav class="app-nav app-nav-footer">
                        <ul class="app-menu footer-menu list-unstyled">
                            <!--//nav-item-->
                            <li class="nav-item">
                                <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                                <router-link to="/settings" class="nav-link">
                                    <span class="nav-icon">
                                        <i class="fa fa-cog"></i>
                                    </span>
                                    <span class="nav-link-text">Settings</span>
                                </router-link><!--//nav-link-->
                            </li>
                            <!--//nav-item-->
                            <li class="nav-item">
                                <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                                <a class="nav-link" :href="helpUrl" target="_blank">
                                    <span class="nav-icon">
                                        <i class="far fa-question-circle"></i>
                                    </span>
                                    <span class="nav-link-text">Help</span>
                                </a><!--//nav-link-->
                            </li>
                            <!--//nav-item-->
                        </ul>
                        <!--//footer-menu-->
                    </nav>
                </div>
                <!--//app-sidepanel-footer-->
            </div>
            <!--//sidepanel-inner-->
        </div>
        <!--//app-sidepanel-->
    </header>
</template>

<script>
import * as navbar from "@/services/navbar.service";
import { webAuth } from "@/services/auth0.service";
import orderHubService from "@/services/order-hub.service";

export default {
    data: () => ({
        merchant: localStorage.getItem("merchant"),
        helpUrl: process.env.VUE_APP_HELP_URL,
    }),
    computed: {
        // Access the notification count directly from the Vuex store
        notificationCount() {
            return this.$store.state.notifications.count;
        },
    },

    mounted() {
        orderHubService.startConnection().then(() => {
            // Listen to the 'ReceiveMessage' event from the hub
            orderHubService.onReceiveMessage((message) => {
                // Handle received message
                console.log('Received message:', message);
                this.$store.commit('increment');
                navbar.init(this.$route);
            });

            // Connection is established, call addToGroup method
            orderHubService.addToGroup(localStorage.getItem("merchantId"))
                .then(() => {
                    // Handle successful addToGroup invocation
                    console.log('Added to group: groupName');
                })
                .catch((error) => {
                    // Handle error
                    console.error('Failed to add to group: groupName', error);
                });
        }).catch((error) => {
            // Handle connection start error
            console.error('Failed to start SignalR connection', error);
        });
    },

    methods: {
        onMenuClick() {
            document
                .getElementById("app-sidepanel")
                .classList.add("sidepanel-visible");
        },

        onMenuClose() {
            document
                .getElementById("app-sidepanel")
                .classList.remove("sidepanel-visible");
        },

        logOut() {
            localStorage.clear();

            webAuth.logout({
                returnTo: window.location.origin,
                clientID: process.env.VUE_APP_AUTH0_CLIENTID,
            });
        },

        switchMerchant() {
            localStorage.removeItem("merchant");
            window.location.href = "/init";
        },
    },
};
</script>

<style scope>
.app-nav .nav-icon {
    position: absolute;
    left: 1rem;
    top: initial;
}
</style>